<template>
	<div id="toggle" class="toggle-switch">
		<div style="position: relative; display: inline-grid; margin-top: 10px;">
			<input type="range" class="slideToUnlock" min="0" max="100" value="0" v-model="sliderValue" @change="slider">
			<span id="range-text" class="range-label" v-text="check ? toggleOn : toggleOff"></span>
		</div>
	</div>
</template>

<script>

export default {
	name: 'phi-toggle',

	props: {
		label: {
			type: String,
			required: false
		},

		toggleOn: {
			type: String,
			required: true
		},

		toggleOff: {
			type: String,
			required: true
		},

		checked: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	data() {
		return {
			check: this.checked,
			sliderValue: 0
		}
	},

	mounted() {
		if (this.check) {
			this.check ? this.sliderValue = 100 : this.sliderValue = 0;
			this.changeIcon(true);
		}
	},

	watch: {
		checked(newVal) {
			this.check = newVal;
			newVal ? this.sliderValue = 100 : this.sliderValue = 0;
			this.changeIcon(newVal);
		}
	},

	methods: {
		/*
		toggle(val) {
			this.check = val;
			this.$emit('change', this.check);
		},
		*/

		toggle(val) {
			this.check = val;
			this.$emit('changeCheck', this.check);
		},

		changeIcon(val) {
			var elem = document.querySelector('.slideToUnlock');
			val ? elem.classList.add('back') : elem.classList.remove('back');
		},

		slider(event) {
			var value = event.target.value;

            if (value >= 50) {
                this.sliderValue = 100;
                this.changeIcon(true);
                this.toggle(true);
            } else if (value < 50) {
                this.sliderValue = 0;
                this.changeIcon(false);
                this.toggle(false);
            }
		}

		// https://vuejs.org/v2/guide/transitions.html
	}
}
</script>

<style lang="css" scoped>
#toggle {
	/*height: 130px;*/
	width: 100%;
	text-align: center;
	margin: auto;
}
.toggle-switch {
	background-color: white;
	background: linear-gradient(180deg, rgba(255,255,255,.20), whitesmoke);
}
.targetName {
	font-size: 30px;
	text-transform: uppercase;
	color: grey;
}
/* ---- stuff for range input */
/*https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/ */
input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: white;
	box-shadow: inset 0 1px 4px rgba(0,0,0,.3);
    border-radius: 22px;
    margin: auto;
    padding: 0px;
    position: relative;
    transition: opacity 0.5s;
    text-align: center;
    width: 280px;
    height: 45px;
    z-index: 2 !important;
}
input[type=range]:focus {
	outline: none;
}
input[type=range]::-moz-range-track {
	background-color: transparent;
	height: 45px;
	width: 100%;
}
input[type=range]::-ms-track {
	width: 100%;
	cursor: pointer;
	background: transparent;
	border-color: transparent;
}
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #1C89B8;
	background-image: url(/static/img/arrow_forward.png);
	background-repeat: no-repeat;
	background-position: center center;
    border-radius: 50%;
    cursor: pointer;
    height: 45px;
    position: relative;
    width: 45px;
    z-index: 9;
    transition: all .3s ease;
}


/* chrome */
.back[type="range"]::-webkit-slider-thumb {
	background-color: red;
}
.back::-webkit-slider-thumb {
	background-image: url(/static/img/arrow_back.png) !important;
}

/* moz */
.back[type=range]::-moz-range-thumb {
	background-color: red;
}
.back::-moz-range-thumb {
	background-image: url(/static/img/arrow_back.png) !important;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
	background-color: #1C89B8;
	background-image: url(/static/img/arrow_forward.png);
	background-repeat: no-repeat;
	background-position: center center;
    border-radius: 50%;
    cursor: pointer;
    height: 45px;
    position: relative;
    width: 45px;
    z-index: 9;
    transition: all .3s ease;
}
/* All the same stuff for IE */
input[type=range]::-ms-thumb {
	background-color: #1C89B8;
	background-image: url(/static/img/arrow_forward.png);
	background-repeat: no-repeat;
	background-position: center center;
    border-radius: 50%;
    cursor: pointer;
    height: 45px;
    position: relative;
    width: 45px;
    z-index: 9;
}
/*input[type="range"]::after {
    content: "deslize para iniciar recorrido";
    color: grey;
    left: 55px;
    position: absolute;
    top: 13px;
    z-index: 1;
    text-transform: uppercase;
}*/
#range-text {
    left: 40px;
    right: 40px;
    position: relative;
    top: -29px;
    text-align: center;
    width: 200px;
    z-index: 2;
}
.range-label {
	z-index: 1;
	color: grey;
	width: 100%;
	font-size: 14px;
	text-align: center;
	text-transform: uppercase;
	transition: opacity 0.5s;
}
</style>
