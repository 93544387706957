<template>
	<!--<div class="phi-card" style="margin-top: 5px;">-->
		<gmap-map
			:center="geoLast"
			:zoom="zoom"
			style="width: 100%; height: 92vh;"
			ref="trackingMap"
		>
		</gmap-map>
	<!--</div>-->
</template>

<script>
// https://www.w3schools.com/graphics/tryit.asp?filename=trysvg_path
// https://github.com/mapsplugin/cordova-plugin-googlemaps-doc/blob/master/v2.0.0/README.md
// https://codeburst.io/native-google-maps-and-geolocation-ionic-fe635a00249d

// ---- To use witout google maps plugin
// https://github.com/dpa99c/cordova-diagnostic-plugin#switchtolocationsettings
// Just a trick to catch who calls alert function
/*
var old = alert;
alert = function(msg) {
	console.log(new Error().stack);
	old.apply(window, arguments);
};
https://stackoverflow.com/questions/31456273/calculate-my-speed-with-geolocation-api-javascript
*/
import * as VueGoogleMaps from "vue2-google-maps";
import Vue from "vue";
import app from "../../store/app.js";

Vue.use(VueGoogleMaps, {
	load: {
		key: "AIzaSyCpSKazmH7T7ArBkdcPiRYaYG_QKhj7rRM",
		libraries: "geometry",
		v: "3.32"
	}
});

var intervalPosition = null;

export default {
	props: {
		target: {
			type: String,
			required: true
		},
		every: {
			type: Number,
			required: true
		},
		enabled: {
			type: Boolean,
			required: true
		},
		targetName: {
			type: Object,
			required: true
		},
		speakRoute: {
			type: Boolean,
			required: true
		}
	},

	data() {
		return {
			app,
			position: {
				latitude: null,
				longitude: null,
				altitude: null,
				accuracy: null,
				altitudeAccuracy: null,
				heading: null,
				speed: null,
				author: null,
				timestamp: null,
				client: null,
				target: null
			},
			geoOptions: {
				enableHighAccuracy: true,
				maximumAge: 3000,
				timeout: 10000
			},
			interval: null,
			geoLast: {
				lat: 0,
				lng: 0
			},
			heading: 0,
			pluginMobile: isMobile,
			mapMarker: null,
			map: null,
			mapDiv: null,
			zoom: 16,
			tilt: 14,
			bearing: 0,
            isVisible: false,
            lang: null,
            gpsOk: false,
            mapStyle: {
				hiding: [
					{
						featureType: "administrative",
						elementType: "geometry",
						stylers: [{
							visibility: "off"
						}]
					},
					{
						featureType: "poi",
						stylers: [{
							visibility: "off"
						}]
					},
					{
						featureType: "road",
						elementType: "labels.icon",
						stylers: [{
							visibility: "off"
						}]
					},
					{
						featureType: "road.highway.controlled_access",
						elementType: "geometry.fill",
						stylers: [{
							visibility: "off"
						}]
					},
					{
						featureType: "transit",
						stylers: [{
							visibility: "off"
						}]
					}
				]
			}
		}
	},

	mounted() {

		if (!navigator.geolocation) {
			console.log(this.$t('notice.geolocalizationNotSupported'));
			return;
		}

		// https://stackoverflow.com/questions/4872702/get-available-locales-for-text-to-speech-tts
		switch (this.app.data.language) {
			case 'es': this.lang = 'es-CO'; break;
			case 'en': this.lang = 'en-US'; break;
			case 'de': this.lang = 'de-DE'; break;
			default:   this.lang = 'en-GB'; 
		}

		/* For vue2-google-maps => 0.10.2*/
		VueGoogleMaps.loaded.then(() => {
			this.$refs.trackingMap.$mapPromise.then(map => {
				this.map = map;
				// https://developers.google.com/maps/documentation/javascript/controls#Adding_Controls_to_the_Map
				map.setOptions({ 
					rotation: false, 
					autoRefresh: true,
					streetViewControl: false,
					rotateControl: false,
					scaleControl: false,
					fullscreenControl: false,
					zoomControl: false,
					styles: this.mapStyle.hiding,
					mapTypeControl: false,
					panControl: false,
					mapTypeId: google.maps.MapTypeId.ROADMAP
				});

				if (isMobile) {
					if (!cordova.plugins.backgroundMode.isActive()) {
						cordova.plugins.backgroundMode.setEnabled(true);
						cordova.plugins.backgroundMode.setDefaults({ silent: true });
					}

			  		cordova.plugins.backgroundMode.on("activate", () => {
						console.log("activate");
						if (this.enabled) {
							navigator.geolocation.clearWatch(this.interval);
						}
			  		});

					cordova.plugins.backgroundMode.on("deactivate", () => {
						console.log("deactivate");
						if (this.enabled) {
				  			clearInterval(this.interval);
				  			this.interval = null;
				  			this.interval = navigator.geolocation.watchPosition(this.pushPosition, error => this.$emit("trackMessage", this.handleError(error)), this.geoOptions);
						}
						if (device.platform == "iOS" && !this.enabled) {
						//if (isMobile && !this.enabled) {
							cordova.plugins.diagnostic.isLocationEnabled(enabled => {
								if (enabled) 
									return;
								else
									this.promiseGpsDetection();
							}, error => {
								console.log(error);
							});
						}
					});

					cordova.plugins.backgroundMode.on("failure", () => {this.$emit("trackMessage", this.$t('notice.generalFailure'));});

					this.promiseGpsDetection();
				} else {
					this.initialPosition();
				}
	  		});
		});
	},

	beforeDestroy() {
		if (isMobile) {
			cordova.plugins.backgroundMode.un("activate", () => {
				clearInterval(this.interval);
			});
			cordova.plugins.backgroundMode.un("deactivate", () => {
				clearInterval(this.interval);
			});
			cordova.plugins.backgroundMode.setEnabled(false);
			window.plugins.insomnia.allowSleepAgain(); 
		}
		navigator.geolocation.clearWatch(this.interval);
		this.interval = null;
	},

	watch: {
		enabled: function(value) {
			console.log("New value: ",value)
			if (value) {
				console.log("Activado... TTS")
				console.log("isMobile ",isMobile)
				console.log("Volume ",this.speakRoute)
				// Put the screen On forever 
				// Check if GPS and location service of the first position is right ???
				isMobile ? window.plugins.insomnia.keepAwake() : '';

				if (isMobile && this.speakRoute) {
					console.log("Hablando ?")
					TTS.speak({ text: this.$t('notice.starting a')+" "+this.targetName.name, locale: this.lang }, 
						() => { 
							console.log('success'); 
						}, (err) => {
							console.log(err);
					});
				}
				
				this.trackPosition();
			} else {
				if (isMobile && this.speakRoute) {
					TTS.speak({ text: this.$t('notice.routeFinish')+" "+this.targetName.name, locale: this.lang }, 
						() => { 
							console.log('success'); 
						}, (err) => {
							console.log(err);
					});
				}
				// Allow screen go to sleep
				isMobile ? window.plugins.insomnia.allowSleepAgain() : ''; 
				navigator.geolocation.clearWatch(this.interval);
				console.log("limpiando")
			}
		},
	// deprecated if i use watchPosition
		every: function(value) {
			clearInterval(this.interval);
			this.trackPosition();
		},

		gpsOk: function(value) {
			this.$emit("gpsresult", value);
		}
	},

	methods: {
		initialPosition() {
			let schoolPosition = new google.maps.LatLng(app.data.organization.latitude, app.data.organization.longitude);

			// school icon
			let marker = new google.maps.Marker({
				position: schoolPosition,
				map: this.map,
				icon: {
					url: "https://s3.amazonaws.com/phidias-felipe/svg/school.png",
					origin: new google.maps.Point(0, 0)
				},
				draggable: false,
				title: this.app.data.organization.name
			});

			// car icon
			let carImage = {
				url: this.targetName.icon,
				origin: new google.maps.Point(0, 0)
			};

			let infoMarker = new google.maps.InfoWindow({
                content: "<h3>" +this.targetName.name +"</h3>"
            });

			navigator.geolocation.getCurrentPosition(data => {
				this.$emit("trackMessage", this.$t('startRoute')+": "+this.targetName.name );
				this.gpsOk = true;
				this.geoLast.lat = data.coords.latitude,
				this.geoLast.lng = data.coords.longitude;

				this.mapMarker = new google.maps.Marker({
					position: new google.maps.LatLng(this.geoLast.lat,this.geoLast.lng),
					map: this.map,
					icon: carImage,
					title: this.targetName.name,
					draggable: false
				});

				this.mapMarker.addListener("click", () => {
					infoMarker.open(this.map, this.mapMarker);
					this.isVisible = !this.isVisible;
				});

				this.map.panTo(new google.maps.LatLng(this.geoLast.lat, this.geoLast.lng));
			}, error => this.$emit("trackMessage", this.handleError(error)), this.geoOptions);
		},

		promiseGpsDetection() {
			let gps = new Promise((resolve, rejected) => {
				this.requestLocationAccuracy(resolve, rejected);
			});
			gps.then(successMessage => {
				// Wait three seconds until GPS initiate
				if (!this.enabled) {
					setTimeout(()=>{ this.initialPosition(); }, 3000);
					this.$emit("trackMessage", this.$t('notice.establishingYourPosition'));
				}
				return;
			}).catch(errorMessage => {
				// this.promiseGpsDetection();
				this.$emit("trackMessage", errorMessage);
			});
		},
		handleLocationAuthorizationStatus(cb, status) {
			switch (status) {
				case cordova.plugins.diagnostic.permissionStatus.GRANTED:
					cb(true);
					break;
				case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
					this.requestLocationAuthorization(cb);
					break;
				case cordova.plugins.diagnostic.permissionStatus.DENIED:
					cb(false);
					break;
				case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
					cb(false);
					break;
				case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
					cb(true);
					break;
			}
		},
		requestLocationAuthorization(cb) {
			cordova.plugins.diagnostic.requestLocationAuthorization(this.handleLocationAuthorizationStatus.bind(this, cb),() => { alert("error"); });
		},
		ensureLocationAuthorization(cb) {
			cordova.plugins.diagnostic.getLocationAuthorizationStatus(this.handleLocationAuthorizationStatus.bind(this, cb),() => { alert("error"); });
		},
		requestLocationAccuracy(resolve, rejected) {
			let that = this;
			this.ensureLocationAuthorization(function(isAuthorized) {
				console.log("isAuthorized: "+ isAuthorized);
				if (isAuthorized) {
					if (device.platform == "iOS") {
						resolve(that.$t('notice.gpsSuccessfullEnable'));
						return;
					} else {
						cordova.plugins.locationAccuracy.canRequest(canRequest => {
							console.log("canRequest: "+canRequest);
							if (canRequest) {
								cordova.plugins.locationAccuracy.request(() => { resolve(that.$t('notice.gpsSuccessfullEnable')); }, error => {
									console.log(error);
									if (error) {
										//rejected("error code=" + error.code + "; error message=" + error.message);
										//if (error.code !== cordova.plugins.locationAccuracy.ERROR_USER_DISAGREED) {
										if (error.code == 4) {
											navigator.notification.confirm(that.$t('notice.gpsLocationAccuracy'), res => {
												if (res == 1) {
													cordova.plugins.diagnostic.switchToLocationSettings();
												} else {
													rejected(that.$t('notice.errorLocationAccuracy'));
												}
										  	}, that.$t('notice.positionNotAvailable'), [that.$t('action.enable'), that.$t('action.cancel')]);
								  		}
									}
								}, cordova.plugins.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY);
							} else {
								rejected(that.$t('notice.errorLocationAccuracy'));
							}
						});
					}
				} else {
					if (device.platform == "iOS") {
						navigator.notification.confirm(that.$t('notice.enableLocalizationToGetPosition'), res => {
							if (res == 1) {
								cordova.plugins.diagnostic.switchToSettings();
							} else {
								// rejected("No es posible obtener su ubicación");
								rejected(that.$t('notice.userDeniedPermissionLocation'));
								that.promiseGpsDetection();
							}
						}, that.$t('notice.positionNotAvailable'), [that.$t('noun.settings'), that.$t('action.cancel')]);
					}
				}
			});
		},
		pushPosition(location) {

	  		let cliente = null;

			if (!navigator.geolocation) {
				console.log(this.$t('notice.geolocalizationNotSupported'));
				return;
			}

			this.position.latitude         = location.coords.latitude, // + (Math.random() * 0.005),
			this.position.longitude        = location.coords.longitude, // + (Math.random() * 0.005),
			this.position.altitude         = location.coords.altitude,
			this.position.accuracy         = location.coords.accuracy,
			this.position.altitudeAccuracy = location.coords.altitudeAccuracy,
			this.position.heading          = location.coords.heading,
			this.position.speed            = location.coords.speed,
			this.position.timestamp        = Math.round(location.timestamp / 1000),
			this.position.target           = this.targetName.id;

			app.api
				.post(`geo/targets/${this.target}/position`, this.position)
				.then(data => this.$emit("update", Object.assign({}, this.position)));

			this.markPosition(this.position);
		},
		markPosition(data) {
			let newPosition  = new google.maps.LatLng(data.latitude, data.longitude);
			this.heading     = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(this.geoLast.lat, this.geoLast.lng),newPosition);
			this.geoLast.lat = data.latitude;
			this.geoLast.lng = data.longitude;
			this.map.setHeading(this.heading);
			this.mapMarker.setPosition(newPosition);
			this.map.panTo(newPosition);
		},
		trackPosition() {
			if (!this.enabled) {
				return;
			}
			this.interval = navigator.geolocation.watchPosition(this.pushPosition, error => this.$emit("trackMessage", this.handleError(error)),this.geoOptions);
			//this.interval = setInterval(this.pushPosition, this.every);
		},
		handleError(data) {
			if (data.code == 3 || data.code == 2) {
				navigator.geolocation.getCurrentPosition(this.pushPosition, error => this.$emit("trackMessage", this.handleError(error)),this.geoOptions);
			}
			var errors = {
                1: this.$t('notice.permissionDenied'),
                2: this.$t('notice.positionNotAvailable'),
                3: this.$t('notice.establishingYourPosition')
			};
			return errors[data.code];
		}
	}
};
</script>

<style lang="css" scoped>
	.mapContainer {
		background-color: rgba(0, 0, 0, 0);
	}
	#map_canvas {
		background-color: rgba(0, 0, 0, 0);
		bottom: 0;
		position: relative;
		overflow: hidden;
		height: 77vh;
		width: 100%;
	}
</style>
